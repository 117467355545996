import React, { useState } from "react";
import Modal from "./components/Modal";
// import useWindowDimensions from "./hooks/useWindowDimensions";
import "./App.css";
import FileBase64 from "react-file-base64";
import { db, set, ref, push } from "./utils/firebase";

const logo = require("./assets/logo_dPen_cropped_white.png");

function App() {
  const [image, setImage] = useState("");
  const [show, setShow] = useState(false);

  // const { width, height } = useWindowDimensions();

  const handleImageUpload = async (e) => {
    const b64string = e.base64.split(",")[1];
    setImage(b64string);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    if (image !== "") {
      try {
        const id = await push(ref(db, "images"));

        await set(id, image);
        setShow(true);
      } catch (error) {
        console.log("ERROR: ", error);
      }
    }
  };
  return (
    <div className="App">
      <Modal
        title="Success!"
        message="Bild in Datenbank gespeichert."
        show={show}
        onSubmit={() => setShow(false)}
      />
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>Upload your image</p>

        <form onSubmit={(e) => onSubmitHandler(e)}>
          <FileBase64
            className="File-input"
            multiple={false}
            onDone={(e) => handleImageUpload(e)}
          />

          <input type="submit" title="Submit" />
        </form>
      </header>
    </div>
  );
}

export default App;
