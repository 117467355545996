// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { set, ref, getDatabase, push } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBO2vcHj0dziejs3O3yerX0pOoVBixlZWs",
  authDomain: "dapp-a2bcf.firebaseapp.com",
  databaseURL:
    "https://dapp-a2bcf-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "dapp-a2bcf",
  storageBucket: "dapp-a2bcf.appspot.com",
  messagingSenderId: "636296935763",
  appId: "1:636296935763:web:dcd68a8e495ccf253009a6",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const db = getDatabase();
export { set, ref, push };
