import React from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";

type ModalProps = {
  title: string;
  message: string;
  show?: boolean;
  onSubmit: () => void;
};

const Modal = (props: ModalProps) => {
  const { width, height } = useWindowDimensions();

  if (!props.show) {
    return;
  }
  return (
    <div
      className="modalContainer"
      style={{ top: height / 2 - 150, left: width / 2 - 150 }}
    >
      <h2>{props.title}</h2>
      <hr />
      <p>{props.message}</p>
      <button
        style={{
          padding: 5,
          backgroundColor: "blue",
          paddingLeft: 10,
          paddingRight: 10,
          color: "white",
          borderRadius: 10,
        }}
        onClick={props.onSubmit}
      >
        OK
      </button>
    </div>
  );
};

export default Modal;
